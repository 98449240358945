import { render, staticRenderFns } from "./_information.vue?vue&type=template&id=0c3dbddb&scoped=true&"
import script from "./_information.vue?vue&type=script&lang=js&"
export * from "./_information.vue?vue&type=script&lang=js&"
import style0 from "./_information.vue?vue&type=style&index=0&id=0c3dbddb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c3dbddb",
  null
  
)

export default component.exports