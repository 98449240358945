<template>
    <div>
        <input
            type="text"
            v-model="formattedValue"
            @input="formatInput"
            @blur="onBlur"
            :class="{ 'border-danger': error }"
            class="form-control form-control-sm"
            :disabled="disabled" />
    </div>
</template>

<script>
import { formatMoney } from '../helpers/formatCurrencies';

export default {
    name: 'CurrencyInput',
    props: {
        disabled: { type: Boolean },
        inputValue: { type: String, default: '0.00' },
        error: { type: Boolean, default: false },
    },

    data(data) {
        return {
            formattedValue: parseFloat(data?.inputValue).toFixed(2),
        };
    },
    watch: {
        inputValue: function (newVal) {
            this.formattedValue = parseFloat(newVal).toFixed(2);
        },
    },
    methods: {
        formatInput(event) {
            this.formattedValue = formatMoney(event.target.value);
            const inputToFloat = parseFloat(this.formattedValue);
            this.$emit('input', inputToFloat);
        },
        onBlur() {
            this.$emit('blur');
        },
    },
};
</script>

<style lang="scss" scoped></style>
