<template>
    <div class="modal" id="modal-file-upload" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">File Upload</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <h2 class="text-center fs-6">Please select a document file to upload:</h2>
                    <p class="text-muted text-center">*Only JPG, JPEG, GIF, DOC, DOCX, PNG, PDF or TIFF files are allowed</p>
                    <input type="text" v-model="description" v-show="showInputDescription" placeholder="File description" class="form-control">
                    <div class="drop-files p-5 my-4 rounded-2 text-center"  @click="browserFile">
                        <loading :show="uploading"></loading>
                        <div class="d-flex px-5" :class="{'d-none': uploading }">
                            <div class="me-4"><i class="fas fa-cloud-upload-alt fs-1"></i></div>
                            <span>Click to browse or drag and drop your files</span>
                        </div>
                    </div>
                    <input type="file" ref="fileInput" @change="onChange" class="d-none">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";
import Loading from "@/components/Loading";

export default {
    name: "FileUpload",
    components: {
        Loading
    },
    props:{
        uploadUrl: {
            type: String
        },
        showInputDescription: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modal: null,
            description: '',
            fileInput: null,
            uploading: false,
        }
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-file-upload'));
        this.fileInput = this.$refs.fileInput;
    },
    methods: {
        close() {
            this.modal.hide();
        },
        onChange() {
            this.uploading = true;

            const formData = new FormData();
            formData.append('file', this.fileInput.files[0]);

            axios
                .post(this.uploadUrl, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    params: {
                        description: this.description
                    }
                })
                .then((response) => {
                    this.$emit('uploaded', response.data);
                    this.modal.hide();
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    return false;
                })
                .finally(() => {
                    this.uploading = false;
                });
        },
        browserFile() {
            this.fileInput.click();
        },
        show() {
            this.data = null;
            this.modal.show();
            this.saving = false;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.drop-files {
    color: $primary;
    border: 1px dashed $primary;
    cursor: pointer;
}
</style>