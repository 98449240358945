<template>
    <div v-if="sender">
        <div class="card mb-2">
            <div class="card-header small-card-header d-flex align-items-center">
                <h6 class="fw-bold m-0">Sender</h6>
                <div class="ms-auto">
                    <button v-show="sender.senderId !== '' && !senderAggregation" type="button" @click="getSenderAggregations" class="btn btn-sm btn-primary me-1 tiny-button"><i class="fas fa-sync"></i> Activity</button>
                    <button type="button" @click="openFindSenderModal" class="btn btn-sm btn-primary me-1 tiny-button"><i class="fas fa-search"></i> Find Sender</button>
                    <button type="button" @click="clearSender" class="btn btn-sm btn-outline-primary ms-1 tiny-button">Clear</button>
                </div>
            </div>
            <form autocomplete="off">
                <div class="card-body p-2">
                    <div class="row small-row alert alert-primary" v-if="senderAggregation">
                        <div class="col-md-4">
                            <label class="fw-bold">Activity in past 30 days</label><br />
                            <!-- ({{ senderAggregation.transactions30Days }})  -->
                            ${{ senderAggregation.aggregation30DaysFormated }}
                        </div>
                        <div class="col-md-4 text-center">
                            <label class="fw-bold">90 days</label><br />
                            <!-- ({{ senderAggregation.transactions90Days }})  -->
                            ${{ senderAggregation.aggregation90DaysFormated }}
                        </div>
                        <div class="col-md-4 text-end">
                            <label class="fw-bold">365 days</label><br />
                            <!-- ({{ senderAggregation.transactions365Days }})  -->
                            ${{ senderAggregation.aggregation365DaysFormated }}
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col">
                            <label for="fullName" class="form-label m-0 tiny-font">Full name</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" @keyup.enter="openFindSenderModal" :class="{ 'border-danger': showError('fullName') }" id="fullName" v-model="sender.fullName" placeholder="" :disabled="!isNewSender" />
                            <span class="text-danger" v-show="showError('fullName')">{{ showError('fullName') ? showError('fullName').Message : '' }}</span>
                        </div>
                        <div class="col-md-5 col-6">
                            <label for="telephone" class="form-label m-0 tiny-font">Telephone</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input id="telephone" autocomplete="none" type="text" v-model="sender.phone" @keydown="numberInputMask" @keyup.enter="openFindSenderModal" :class="{ 'border-danger': showError('phone') }" class="form-control form-control-sm" />
                            <span class="text-danger" v-show="showError('phone')">{{ showError('phone') ? showError('phone').Message : '' }}</span>
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col-md-7 col-6 mb-2 mb-md-0">
                            <label for="companyName" class="form-label m-0 tiny-font">Company name</label>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" @keyup.enter="openFindSenderModal" :class="{ 'border-danger': showError('company') }" v-model="sender.company" id="companyName" placeholder="" :disabled="!isNewSender" />
                            <span class="text-danger" v-show="showError('company')">{{ showError('company') ? showError('company').Message : '' }}</span>
                        </div>
                        <div class="col-md-5 col-6 mb-2 mb-md-0">
                            <label class="form-label m-0 tiny-font" for="customerNumber">Customer number</label>
                            <span v-if="!isNewSender" class="badge bg-light text-black form-control fs-5 py-1 border-light">{{ sender.clientId }}</span>
                            <input id="customerNumber" v-model="sender.senderId" autocomplete="none" v-else type="text" maxlength="50" @keydown="numberInputMask" @keyup.enter="openFindSenderModal" class="form-control form-control-sm" />
                        </div>
                    </div>
                </div>
                <hr class="p-0 m-0" />
                <div class="card-body p-2">
                    <div class="row small-row">
                        <div class="col-4">
                            <label for="senderCountry" class="form-label m-0 tiny-font">Country</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <select-country class="form-select-sm" :data="countries.data" v-model="sender.countryId" :value="sender.countryId" @change="onChangeSenderCountry" id="senderCountry"></select-country>
                        </div>
                        <div class="col-8">
                            <label for="streetAddress" class="form-label m-0 tiny-font">Street Address</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" :class="{ 'border-danger': showError('address') }" v-model="sender.address" id="streetAddress" placeholder="" />
                            <span class="text-danger" v-show="showError('address')">{{ showError('address') ? showError('address').Message : '' }}</span>
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col-4 col-md-5">
                            <label for="city" class="form-label m-0 tiny-font">City</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" :class="{ 'border-danger': showError('city') }" v-model="sender.city" id="city" placeholder="" />
                            <span class="text-danger" v-show="showError('city')">{{ showError('city') ? showError('city').Message : '' }}</span>
                        </div>
                        <div class="col-4">
                            <label for="state" class="form-label m-0 tiny-font">State</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" :class="{ 'border-danger': showError('stateIsoCode') }" v-model="sender.stateName" placeholder="" v-show="!showAmericanStates" />
                            <select class="form-select form-select-sm" :class="{ 'border-danger': showError('stateIsoCode') }" v-model="sender.stateIsoCode" id="state" @change="onChangeSenderState" v-show="showAmericanStates" :disabled="states.loading">
                                <option :value="states.loading && sender ? sender.stateIsoCode : 0" selected>{{ states.loading ? 'Loading...' : '- Select -' }}</option>
                                <option v-for="(item, index) in states.data" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('stateIsoCode')">{{ showError('stateIsoCode') ? showError('stateIsoCode').Message : '' }}</span>
                        </div>
                        <div class="col-4 col-md-3">
                            <label for="zipCode" class="form-label m-0 tiny-font">Zip code</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input id="zipCode" v-model="sender.zip" autocomplete="none" type="text" class="form-control form-control-sm" maxlength="10" @keydown="numberInputMask" @focusout="loadCityStateFromZipCode" :class="{ 'border-danger': showError('zip') }" />
                            <span class="text-danger" v-show="showError('zip')">{{ showError('zip') ? showError('zip').Message : '' }}</span>
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col">
                            <label for="email" class="form-label m-0 tiny-font">Email</label>
                            <input id="email" v-model="sender.email" autocomplete="none" type="text" maxlength="80" @blur="emailInputValidation" :class="{ 'border-danger': showError('email') }" class="form-control form-control-sm" />
                            <span class="text-danger tiny-font" v-show="showError('email')">{{ showError('email') ? showError('email').Message : '' }}</span>
                        </div>
                        <div class="col-3">
                            <label for="birthday" class="form-label m-0 tiny-font">Birthday</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" :class="{ 'border-danger': showError('birth') }" :value="sender.birth" @change="onChangeBirth" id="birthday" placeholder="mm/dd/yyyy" />
                            <span class="text-danger" v-show="showError('birth')">{{ showError('birth') ? showError('birth').Message : '' }}</span>
                        </div>
                    </div>
                </div>
                <hr class="p-0 m-0" />
                <div class="card-body p-2">
                    <h6 class="fw-bold m-0">Sender documents</h6>
                    <div class="row small-row">
                        <div class="col-1 align-self-end">
                            <div class="btn-group">
                                <a @click="openUploadFile('doc1FileUpload')" class="m-1" v-show="!sender.document1Object"><i class="fas fa-upload m-0"></i></a>
                                <a :href="sender.document1Object ? sender.document1Object.imageUrl : '#'" target="_blank" class="me-1" v-show="sender.document1Object"><i class="far fa-file-alt m-0"></i></a>
                                <a @click="deleteDoc1FileUpload" class="ms-1" v-show="sender.document1Object"><i class="far fa-trash-alt m-0"></i></a>
                            </div>
                        </div>
                        <div class="col-2 px-1">
                            <label for="issueBy-1" class="form-label m-0 tiny-font">Issue by</label>
                            <span v-show="document1IssuebyRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                            <select class="form-select form-select-sm" v-model="sender.document1Issueby" @change="onChangeIssueby(sender.document1Issueby)" id="issueBy-1" :class="{ 'is-invalid': document1IssuebyRequired }">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in doc1IssuedBy" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('document1Issueby')">{{ showError('document1Issueby') ? showError('document1Issueby').Message : '' }}</span>
                        </div>
                        <div class="col-2 px-1">
                            <label for="documentType-1" class="form-label m-0 tiny-font">Type</label>
                            <span v-show="document1TypeRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                            <select class="form-select form-select-sm" v-model="sender.document1Type" id="documentType-1" @change="onChangeDoc1Type(sender.document1Type)" :class="{ 'is-invalid': document1TypeRequired }">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in doc1Types" :value="item.value" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('document1Type')">{{ showError('document1Type') ? showError('document1Type').Message : '' }}</span>
                        </div>
                        <div class="col-md-2 px-1">
                            <label for="issueStateBy-1" class="form-label m-0 tiny-font">Issue State</label>
                            <select class="form-select form-select-sm" v-model="sender.document1StateIssueby" id="document1StateIssueby" :disabled="disabledDocument1StateIssueBy">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in issueStates" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                        </div>
                        <div class="col-3 px-1">
                            <label for="documentNumber-1" class="form-label m-0 tiny-font">Number</label>
                            <span v-show="document1NumberRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" :class="{ 'is-invalid': document1NumberRequired }" v-model="sender.document1Number" id="documentNumber-1" placeholder="" />
                            <span class="text-danger" v-show="showError('document1Number')">{{ showError('document1Number') ? showError('document1Number').Message : '' }}</span>
                        </div>
                        <div class="col-2 ps-1">
                            <label for="expirationDate-1" class="form-label m-0 tiny-font">Expiration date</label>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" :value="sender.document1Expiration" id="expirationDate-1" placeholder="mm/dd/yyyy" :class="{ 'border-danger': showError('document1Expiration') }" />
                            <span class="text-danger" v-show="showError('document1Expiration')">{{ showError('document1Expiration') ? showError('document1Expiration').Message : '' }}</span>
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col-1 align-self-end">
                            <div class="btn-group">
                                <a @click="openUploadFile('doc2FileUpload')" class="m-1" v-show="!sender.document2Object"><i class="fas fa-upload m-0"></i></a>
                                <a :href="sender.document2Object ? sender.document2Object.imageUrl : '#'" target="_blank" class="me-1" v-show="sender.document2Object"><i class="far fa-file-alt m-0"></i></a>
                                <a @click="deleteDoc2FileUpload" class="ms-1" v-show="sender.document2Object"><i class="far fa-trash-alt m-0"></i></a>
                            </div>
                        </div>
                        <div class="col-2 px-1">
                            <label for="issueBy-2" class="form-label m-0 tiny-font">Issue by</label>
                            <span v-show="document2IssuebyRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                            <select class="form-select form-select-sm" v-model="sender.document2Issueby" id="issueBy-2" :disabled="true" :class="{ 'is-invalid': document2IssuebyRequired }">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in doc2IssuedBy" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('document2Issueby')">{{ showError('document2Issueby') ? showError('document2Issueby').Message : '' }}</span>
                        </div>
                        <div class="col-4 px-1">
                            <label for="documentType-2" class="form-label m-0 tiny-font">Type</label>
                            <span v-show="document2TypeRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                            <select class="form-select form-select-sm" v-model="sender.document2Type" @change="onChangeDoc2Type" id="documentType-2" :class="{ 'is-invalid': document2TypeRequired }">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in doc2Types" :value="item.value" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('document2Type')">{{ showError('document2Type') ? showError('document2Type').Message : '' }}</span>
                        </div>
                        <div class="col-3 px-1">
                            <label for="documentNumber-2" class="form-label m-0 tiny-font">Number</label>
                            <span v-show="document2NumberRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" v-model="sender.document2Number" id="documentNumber-2" placeholder="" :class="{ 'is-invalid': document2NumberRequired }" />
                            <span class="text-danger" v-show="showError('document2Number')">{{ showError('document2Number') ? showError('document2Number').Message : '' }}</span>
                        </div>
                        <div class="col-2 ps-1">
                            <label for="expirationDate-2" class="form-label m-0 tiny-font">Expiration date</label>
                            <input autocomplete="none" type="text" class="form-control form-control-sm" :value="sender.document2Expiration" id="expirationDate-2" placeholder="mm/dd/yyyy" :disabled="disabledDocument2ExpirationAndIssueBy" :class="{ 'border-danger': showError('document2Expiration') }" />
                            <span class="text-danger" v-show="showError('document2Expiration')">{{ showError('document2Expiration') ? showError('document2Expiration').Message : '' }}</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <modal-file-upload ref="modalUploadFile" upload-url="/niloagent/createorder/savefile" @uploaded="saveUploadedFile"></modal-file-upload>
        <modal-find-sender ref="modalFindSender" @selected="selectedSender" :customer-number="sender.senderId" :company-name="sender.company" :full-name="sender.fullName" :telephone="sender.phone"></modal-find-sender>
    </div>
    <loading v-else />
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import { Datepicker } from 'vanillajs-datepicker';
import SelectCountry from '@/components/SelectCountry';
import ModalFindSender from '@/views/order/modals/FindSender';
import ModalFileUpload from '@/views/order/modals/FileUpload';
import { findSenders } from '../../../helpers/senders';

export default {
    name: 'NewOrderSender',
    components: {
        Loading,
        SelectCountry,
        ModalFindSender,
        ModalFileUpload,
    },
    data() {
        return {
            states: {
                data: [],
                loading: false,
            },
            countries: {
                data: [],
                loading: false,
            },
            doc1Types: [],
            doc2Types: [],
            issueStates: [],
            doc1IssuedBy: [],
            doc2IssuedBy: [],
            docFileUpload: '',
            birthDatePicker: null,
            document1DatePicker: null,
            document2DatePicker: null,
            validating: false,
            errors: [],
            senderAggregation: null,
            loadingSenderAggregation: false,
        };
    },
    async mounted() {
        const senderId = this.$route.query?.senderId;
        if (senderId) {
            const senders = await findSenders({ ClientId: senderId });
            if (senders?.result?.length) {
                const [selectedSender] = senders.result;
                this.selectedSender(selectedSender);
            }
            if (senders?.result?.length === 0) {
                this.$toast.error(`Sender ${senderId} not Found!`);
            }
        }
        if (this.$store.state.simpleOrder.sender != null) {
            this.sender = this.$store.state.simpleOrder.sender;
            this.loadDoc1IssuedBy();
            this.loadDoc1Types();
            this.loadDoc2IssuedBy();
            this.loadDoc2Types();
            this.loadIssueStates();

            if (this.sender.document1Object != null) {
                this.doc1FileUpload = this.sender.document1Object;
            }

            if (this.sender.document2Object != null) {
                this.doc2FileUpload = this.sender.document2Object;
            }

            if (!this.sender.document1StateIssueby) {
                this.sender.document1StateIssueby = '0';
            }

            this.loadCountries();
            this.loadAmericanStates();
            this.setDatePickers();
            this.setDefaultCountryToUSA();
        }
    },
    methods: {
        setDefaultCountryToUSA() {
            this.sender.countryId = 1;
        },
        selectedSender(sender) {
            this.$store.commit('simpleOrder/setSender', sender);

            this.loadDoc1IssuedBy();
            this.loadIssueStates();
            this.loadDoc1Types();
            this.loadDoc2IssuedBy();
            this.loadDoc2Types();
            this.$refs.modalFindSender.close();
            this.setDatePickers();
        },
        async loadCountries() {
            try {
                this.loading = true;
                this.countries.loading = true;
                const response = await axios.get('/niloagent/createorder/filter/country');

                if (response.status === 200) {
                    this.countries.data = response.data;
                    return true;
                }

                return false;
            } catch (error) {
                this.$toast.error(`country: ${error.Message}`);
                return false;
            } finally {
                this.countries.loading = false;
                this.loading = false;
            }
        },
        loadAmericanStates() {
            this.states.loading = true;
            axios
                .get('/niloagent/createorder/filter/sender/state')
                .then((response) => {
                    this.states.data = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`American States: ${error.Message}`);
                })
                .finally(() => {
                    this.states.loading = false;
                });
        },
        loadDoc1Types() {
            this.loading = true;
            axios
                .get('/niloagent/createorder/filter/sender/typedoc', {
                    params: {
                        countryId: this.sender.document1Issueby,
                        documentEnum: 1,
                    },
                })
                .then((response) => {
                    this.doc1Types = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Doc Files: ${error.Message}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadDoc2Types() {
            axios
                .get('/niloagent/createorder/filter/sender/typedoc', {
                    params: {
                        countryId: this.sender.document1Issueby,
                        documentEnum: 2,
                    },
                })
                .then((response) => {
                    this.doc2Types = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Doc Files: ${error.Message}`);
                });
        },
        loadIssueStates() {
            axios
                .get('/niloagent/createorder/filter/sender/issuestate', {
                    params: {
                        countryId: this.sender.document1Issueby,
                    },
                })
                .then((response) => {
                    this.issueStates = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Issue States: ${error.Message}`);
                });
        },
        loadDoc1IssuedBy() {
            axios
                .get('/niloagent/createorder/filter/sender/issuedbyfilter')
                .then((response) => {
                    this.doc1IssuedBy = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Doc Issue by: ${error.Message}`);
                });
        },
        loadDoc2IssuedBy() {
            axios
                .get('/niloagent/createorder/filter/sender/issuedbyfilter', {
                    params: {
                        fromDocumentType: this.sender ? this.sender.document2Type : '',
                    },
                })
                .then((response) => {
                    this.doc2IssuedBy = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Doc Issue by: ${error.Message}`);
                });
        },
        clearZipInformation() {
            this.sender.city = '';
            this.sender.stateName = '';
            this.sender.stateIsoCode = '';
        },
        loadCityStateFromZipCode() {
            if (!this.countryAndZipCodeAreFilled) {
                this.clearZipInformation();
                return;
            }
            axios
                .get('/niloagent/createorder/filter/sender/citystate', {
                    params: {
                        countryId: this.sender.countryId,
                        zipCode: this.sender.zip,
                    },
                })
                .then((response) => {
                    if (response.data == null) {
                        this.clearZipInformation();
                        return;
                    }
                    this.sender.city = response.data.city;
                    this.sender.stateName = response.data.state;
                    this.sender.stateIsoCode = response.data.state;
                })
                .catch((error) => {
                    this.$toast.error(`State: ${error.Message}`);
                });
        },
        onChangeSenderState() {
            this.sender.stateIsoCode = this.selectedSenderState.id;
            this.sender.stateName = this.selectedSenderState.value;
        },
        onChangeSenderCountry() {
            this.sender.stateIsoCode = '';
            this.sender.stateName = '';
            this.loadCityStateFromZipCode();
        },
        openUploadFile(docFileUpload) {
            this.docFileUpload = docFileUpload;
            this.$refs.modalUploadFile.show();
        },
        saveUploadedFile(file) {
            if (this.docFileUpload === 'doc1FileUpload') {
                this.sender.document1Object = file;
                this.sender.document1File = file.fileKey;
            }

            if (this.docFileUpload === 'doc2FileUpload') {
                this.sender.document2Object = file;
                this.sender.document2File = file.fileKey;
            }
        },
        deleteDoc1FileUpload() {
            this.sender.document1Object = null;
            this.sender.document1File = null;
        },
        deleteDoc2FileUpload() {
            this.sender.document2Object = null;
            this.sender.document2File = null;
        },
        setDatePickers() {
            const elemBirthDatePicker = document.getElementById('birthday');

            this.birthDatePicker = new Datepicker(elemBirthDatePicker, { format: 'mm/dd/yyyy' });

            this.onChangeBirth(elemBirthDatePicker);
            if (this.sender.birth) this.birthDatePicker.setDate(this.sender.birth);

            const elemExpirationDate1 = document.getElementById('expirationDate-1');
            this.document1DatePicker = new Datepicker(elemExpirationDate1);
            this.onChangeDocument1Expiration(elemExpirationDate1);
            if (this.sender.document1Expiration) this.document1DatePicker.setDate(this.sender.document1Expiration);

            const elemExpirationDate2 = document.getElementById('expirationDate-2');
            this.document2DatePicker = new Datepicker(elemExpirationDate2);
            this.onChangeDocument2Expiration(elemExpirationDate2);
            if (this.sender.document2Expiration) this.document2DatePicker.setDate(this.sender.document2Expiration);
        },
        onChangeBirth(elem) {
            elem.addEventListener('changeDate', () => {
                this.sender.birth = this.birthDatePicker.getDate('mm/dd/yyyy');
            });
        },
        onChangeDocument1Expiration(elem) {
            elem.addEventListener('changeDate', () => {
                this.sender.document1Expiration = this.document1DatePicker.getDate('mm/dd/yyyy');
            });
        },
        onChangeDocument2Expiration(elem) {
            elem.addEventListener('changeDate', () => {
                this.sender.document2Expiration = this.document2DatePicker.getDate('mm/dd/yyyy');
            });
        },
        showError(field) {
            return (
                this.errors.length > 0 &&
                this.errors.find((value) => {
                    return value.Field == field;
                })
            );
        },
        scrollUp() {
            document.getElementById('sender-information').scrollIntoView();
        },
        clearSender() {
            this.$store.commit('simpleOrder/newSender');
            this.aggregation30DaysFormated = '';
            this.senderAggregation = null;
        },
        openFindSenderModal() {
            this.$refs.modalFindSender.show();
            this.aggregation30DaysFormated = '';
        },
        getSenderAggregations() {
            this.loadingSenderAggregation = true;
            axios
                .get(`/niloagent/order/-1/detail/senderaggregation/${this.sender.clientId}`)
                .then((response) => {
                    this.senderAggregation = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loadingSenderAggregation = false;
                });
        },
        numberInputMask(event) {
            const key = event.key;

            if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
                return;
            }

            const allowedCommandKeys = ['c', 'v', 'a', 'x'];
            const isCtrlOrCmd = event.ctrlKey || event.metaKey;
            if (isCtrlOrCmd && allowedCommandKeys.includes(key.toLowerCase())) {
                return;
            }

            if (!/[0-9]/.test(key)) {
                event.preventDefault();
                return;
            }
        },
        emailInputValidation(event) {
            let email = event.target.value;
            let error = {
                Field: 'email',
                Message: 'Invalid email',
            };
            const emailRegex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
            if (!emailRegex.test(email)) {
                this.errors.push(error);
            } else {
                this.errors = this.errors.filter((e) => e.Field !== error.Field && e.Message !== error.Message);
            }
        },
        onChangeDoc1Type(value) {
            if (value === 'US Passport' || value === 'US Alien Card' || value === 'US Military ID') {
                this.sender.document1StateIssueby = '0';
            }
            this.disabledDocument1StateIssueBy();
        },
        onChangeIssueby(value) {
            this.sender.document1Issueby = value;
            this.sender.document2Issueby = value;

            this.loadDoc1Types();
            this.loadDoc2Types();
            this.loadIssueStates();

            this.sender.document1StateIssueby = '0';
            this.sender.document1Type = '0';
            this.sender.document2Type = '0';

            if (value === 'USA') {
                this.disabledDocument1StateIssueBy();
            }
        },
    },
    computed: {
        sender() {
            return this.$store.state.simpleOrder.sender;
        },
        isNewSender() {
            return !this.sender.clientId > 0;
        },
        showAmericanStates() {
            return this.sender && parseInt(this.sender.countryId) === 1;
        },
        disabledDocument2ExpirationAndIssueBy() {
            return Object.keys(this.sender).length > 0 && this.sender.document2Type ? this.sender.document2Type.includes('CPF') || this.sender.document2Type.includes('Social Security Number') : false;
        },
        disabledDocument1StateIssueBy() {
            return (Object.keys(this.sender).length > 0 && (this.sender.document1Type === 'US Passport' || this.sender.document1Type === 'US Alien Card' || this.sender.document1Type === 'US Military ID')) || this.sender.document1Issueby !== 'USA' ? true : false;
        },
        selectedSenderState() {
            return Object.keys(this.sender).length > 0 && this.states.length > 0 && this.sender.stateIsoCode != '' ? this.states.find((item) => item.id === this.sender.stateIsoCode) : { id: this.sender.stateIsoCode, value: this.sender.stateName };
        },
        document2Type() {
            return this.sender.document2Type;
        },
        countryAndZipCodeAreFilled() {
            return parseInt(this.sender.countryId) > 0 && this.sender.zip && this.sender.zip.length > 0;
        },
        loading: {
            get: function () {
                return this.$store.state.simpleOrder.loading;
            },
            set: function (modifiedValue) {
                this.$store.state.simpleOrder.loading = modifiedValue;
            },
        },
        document1NumberRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'document1Number');
        },
        document2NumberRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'document2Number');
        },
        document1TypeRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'document1Type');
        },
        document2TypeRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'document2Type');
        },
        document1IssuebyRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'document1Issueby');
        },
        document2IssuebyRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'document2Issueby');
        },
    },
    watch: {
        document2Type(value) {
            if (value.includes('Social Security Number')) this.sender.document2Issueby = 'USA';
        },
        doc2IssuedBy(value) {
            if (value.length === 1) this.sender.document2Issueby = value[0].id;
        },
        senderstateIsoCode() {
            this.sender.stateIsoCode = this.selectedSenderState.id;
            this.sender.stateName = this.selectedSenderState.value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/simpleOrder.scss';
.btn-group {
    a {
        cursor: pointer;
        font-size: 1.25rem;
    }
}
</style>
