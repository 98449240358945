<template>
    <div class="modal fade" id="modal-find-recipient" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Find Recipient</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <loading :show="loading" />
                    <div>
                        <div class="alert alert-danger" v-if="error">
                            {{ error.Message }}
                        </div>
                        <div class="table-responsive" v-show="!loading" v-else>
                            <div>
                                <div class="d-flex gap-2" v-show="!loading">
                                    <div class="col-md-4 mb-3">
                                        <h1 class="page-title text-primary text-uppercase">Results Found {{ pagination.totalRows }} recipients</h1>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <input v-model="filters.fullName" @keyup.enter="loadFindRecipient" id="fullName" autocomplete="none" type="text" for="fullName" class="form-control" placeholder="First and last name" />
                                    </div>
                                    <div class="col-md-2 mb-2">
                                        <button type="button" @click="loadFindRecipient" class="btn btn-primary btn-sm text-uppercase" :disabled="loading" id="findRecipient"><i class="fas fa-search"></i><span v-show="!loading">Find Recipient</span><span v-show="loading">Waiting...</span></button>
                                    </div>
                                    <div id="resultsFound"></div>
                                </div>
                            </div>
                            <div v-if="pagination.totalRows === 0" class="alert alert-warning" v-show="data !== null && data.length === 0">No Recipient Was Found using the criteria specified.</div>
                            <div v-else>
                                <table class="table table-borderless">
                                    <thead class="bg-light">
                                        <tr>
                                            <th class="rounded-start">Client</th>
                                            <th>Doc number</th>
                                            <th>Name</th>
                                            <th>Address</th>
                                            <th class="rounded-end">Phone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in data" :key="item.clientId" @click="selectSender(item)">
                                            <td>{{ item.clientId }}</td>
                                            <td>{{ item.cpfOrCnpj }}</td>
                                            <td>
                                                {{ item.fullName }}
                                                <br />
                                                <strong>{{ item.company }}</strong>
                                            </td>
                                            <td>{{ item.fullAddress }}</td>
                                            <td>{{ item.phone }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination v-show="!loading && pagination.totalRows > 10" :actual-page="pagination.pageNumber" :total-pages="pagination.totalPages" v-on:change="changePage"></pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import Loading from '@/components/Loading';
import Pagination from '@/components/Pagination';

export default {
    name: 'FindRecipientModal',
    components: {
        Loading,
        Pagination,
    },
    props: {
        senderId: String,
        customerNumber: String,
        fullName: String,
        telephone: String,
        adress: String,
        companyName: String,
    },
    data() {
        return {
            modal: null,
            data: null,
            loading: false,
            error: null,
            resultFound: 0,
            pagination: {
                pageNumber: 1,
            },
            filters: {
                fullName: '',
            },
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-find-recipient'));
    },
    methods: {
        loadButton() {
            this.pagination.pageNumber = 1;
            this.load(this.senderId);
        },
        load(senderId) {
            this.loading = true;
            const url = '/niloagent/createordersimple/recipient/find';
            axios
                .get(url, {
                    params: {
                        CustomerNumber: this.customerNumber,
                        ClientId: senderId,
                        FindModal: this.filters.fullName !== '' ? true : false,
                        CompanyName: this.companyName,
                        FullName: this.filters.fullName !== '' ? this.filters.fullName : this.fullName,
                        SdPhone1: this.telephone,
                        pageNumber: this.pagination.pageNumber,
                    },
                })
                .then((response) => {
                    this.data = response.data.result;
                    this.pagination = (({ pageNumber, pageSize, rowFrom, rowTo, totalPages, totalRows }) => ({ pageNumber, pageSize, rowFrom, rowTo, totalPages, totalRows }))(response.data);
                    this.loading = false;
                    this.resultFound = response.data.length;
                    this.scrollUp();
                })
                .catch((error) => {
                    this.error = error.response.data;
                    this.data = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadFindRecipient() {
            this.pagination.pageNumber = 1;
            this.load(this.senderId);
        },
        changePage(page) {
            this.pagination.pageNumber = page;
            this.load(this.senderId);
        },
        scrollUp() {
            document.getElementById('resultsFound').scrollIntoView();
        },
        selectSender(recipient) {
            this.$emit('selected', recipient);
        },
        show(senderId = 0) {
            this.pagination.pageNumber = 1;
            this.data = null;
            this.error = null;
            this.filters.fullName = '';
            this.modal.show();
            this.load(senderId);
        },
        close() {
            this.modal.hide();
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

tbody {
    tr:hover {
        background-color: lighten($primary, 45);
        cursor: pointer;
    }
}
</style>
