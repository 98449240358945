import Tasks from '@/views/task/Tasks';
import UsendList from '@/views/task/lists/Usend';
import PaymentList from '@/views/task/lists/Payment';
import RefundReceipt from '@/views/task/RefundReceipt';
import FinancialList from '@/views/task/lists/Financial';
import ComplianceList from '@/views/task/lists/Compliance';
import TaskGenericResult from '@/views/task/lists/TaskGenericResult';
import ComplianceApprovalTask from '@/views/task/lists/ComplianceApprovalTask';
import UsendProblemOrders from '@/views/task/lists/UsendProblemOrders';
import PendingRefundOrders from '@/views/task/lists/PendingRefundOrders';
import PaidAfterSevenDaysOrders from '@/views/task/lists/PaidAfterSevenDaysOrders';
import UsendAgingProcessOrders from '@/views/task/lists/UsendAgingProcessOrders';
import CustomerServiceProblemOrders from '@/views/task/lists/CustomerServiceProblemOrders';
import CustomerServiceAgingProcessOrders from '@/views/task/lists/CustomerServiceAgingProcessOrders';

export default [
    {
        path: '/tasks',
        name: 'Tasks',
        component: Tasks,
    },
    {
        path: '/tasks/usend-list',
        name: 'Usend List',
        component: UsendList,
    },
    {
        path: '/tasks/financial-list',
        name: 'Financial List',
        component: FinancialList,
    },
    {
        path: '/tasks/compliance-list',
        name: 'Compliance List',
        component: ComplianceList,
    },
    {
        path: '/tasks/payment-list',
        name: 'Payment List',
        component: PaymentList,
    },
    {
        path: '/tasks/pending-refund-orders-list',
        name: 'Pending Refund Orders List',
        component: PendingRefundOrders,
    },
    {
        path: '/tasks/problem-orders-list',
        name: 'Problem Orders List',
        component: CustomerServiceProblemOrders,
    },
    {
        path: '/tasks/aging-process-orders-list',
        name: 'Aging Process Orders List',
        component: CustomerServiceAgingProcessOrders,
    },
    {
        path: '/tasks/paid-after-seven-days-orders-list',
        name: 'Paid After Seven Days Orders List',
        component: PaidAfterSevenDaysOrders,
    },
    {
        path: '/tasks/usend-problem-orders-list',
        name: 'Usend Problem Orders List',
        component: UsendProblemOrders,
    },
    {
        path: '/tasks/usend-aging-process-orders-list',
        name: 'Usend Aging Process Orders List',
        component: UsendAgingProcessOrders,
    },
    {
        path: '/tasks/refund-receipt/:orderId',
        name: 'Refund Receipt',
        component: RefundReceipt,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/tasks/:taskType',
        name: 'Task List',
        component: TaskGenericResult,
        props: true,
    },
    {
        path: '/compliance/hold',
        name: 'Compliance Approval Task',
        component: ComplianceApprovalTask,
    },
];
