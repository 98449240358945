<template>
    <div class="card my-2">
        <div class="card-header small-card-header">
            <h6 class="fw-bold m-0">Additional Information</h6>
        </div>
        <div class="card-body p-2">
            <form autocomplete="off">
                <div class="mb-1">
                    <label for="payMethod" class="form-label d-flex m-0 tiny-font">Pay method <span class="mandatory-mark tiny-mandatory-mark">*</span> <span class="text-muted ms-auto">(received from sender)</span></label>
                    <select v-model="information.payMethod" class="form-select form-select-sm" :class="{ 'is-invalid': payMethodRequired }" id="payMethod" @change="onChangeSenderPayMethod">
                        <option value="0">- Select -</option>
                        <option v-for="item in payMethods" :value="item.id" :key="item.id">{{ item.value }}</option>
                    </select>
                </div>
                <div class="row small-row">
                    <div class="col">
                        <label for="senderOccupation" class="form-label m-0 tiny-font">Sender occupation</label>
                        <span v-show="senderOccupationRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                        <select v-model="information.senderOccupation" v-show="!showOccupation" class="form-select form-select-sm" :class="{ 'is-invalid': senderOccupationRequired }" id="senderOccupation" ref="senderOccupationSelect" @change="onChangeSenderOccupation">
                            <option value="">- Select -</option>
                            <option>Housekeeping | Trabalho doméstico</option>
                            <option>Construction worker | Trabalhador da construção civil</option>
                            <option>Landscape services | Jardineiro</option>
                            <option>Babysitter | Babá</option>
                            <option>Store teller | Atendente de loja</option>
                            <option>Student | Estudante</option>
                            <option>Teacher | Professor(a)</option>
                            <option>Nurse | Enfermeiro(a)</option>
                            <option>Engineer | Engenheiro(a)</option>
                            <option>Accountant | Contador(a)</option>
                            <option>Lawyer | Advogado(a)</option>
                            <option>Doctor | Médico(a)</option>
                            <option>Artist | Artista</option>
                            <option>Chef | Chef de cozinha</option>
                            <option>Driver | Motorista</option>
                            <option>Retired | Aposentado(a)</option>
                            <option>Housewife | Dona de casa</option>
                            <option value="-1">Other | Outro (campo para inserir opção não listada)</option>
                        </select>
                        <div class="input-group input-group-sm" v-show="showOccupation">
                            <input type="text" :class="{ 'is-invalid': senderOccupationRequired }" v-model="information.senderOccupation" class="form-control form-control-sm" id="senderOccupationOther" placeholder="" name="senderOccupationOther" />
                            <button type="button" class="btn btn-light border" @click="resetSenderOccupation">
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col">
                        <label for="sourceOfFunds" class="form-label m-0 tiny-font">Source of funds</label>
                        <span v-show="sourceOfFundsRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                        <div>
                            <div v-if="!trangloOperation">
                                <select v-model="information.sourceOfFunds" v-show="!showSourceOfFunds" class="form-select form-select-sm" :class="{ 'is-invalid': sourceOfFundsRequired }" id="sourceOfFunds" @change="onChangeSourceOfFunds">
                                    <option value="">- Select -</option>
                                    <option>Work | Trabalho</option>
                                    <option>Investments/Savings | Investimentos/Poupança</option>
                                    <option>Golden ticket | Bilhete de Loteria</option>
                                    <option>Compensation | Compensação/Seguro de acidente</option>
                                    <option>Inheritance | Herança</option>
                                    <option>Pension | Pensão</option>
                                    <option>Loan proceeds | Receitas de empréstimo</option>
                                    <option>Sale of property or assets | Venda de propriedade ou ativos</option>
                                    <option value="-1">Other | Outro (campo para inserir opção não listada)</option>
                                </select>
                                <div class="input-group input-group-sm" v-show="showSourceOfFunds">
                                    <input type="text" :class="{ 'is-invalid': sourceOfFundsRequired }" v-model="information.sourceOfFunds" class="form-control form-control-sm" id="sourceOfFunds" placeholder="" name="sourceOfFunds" />
                                    <button type="button" class="btn btn-light border" @click="resetSourceOfFunds">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <select v-model="information.sourceOfFunds" class="form-select form-select-sm" id="sourceOfFunds" name="sourceOfFundsselect">
                                    <option value="">- Select -</option>
                                    <option v-for="item in fundOfSourceOptions" :value="item.id + ' - ' + item.value" :key="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row small-row">
                    <div class="col">
                        <label for="senderRelationshipToRecipient" class="form-label m-0 tiny-font">Sender relationship to recipient</label>
                        <span v-show="senderRelationshipToRecipientRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                        <div v-if="!trangloOperation">
                            <select v-model="information.senderRelationshipToRecipient" v-show="!showRelationshipToRecipient" ref="senderRelationshipToRecipientSelect" class="form-select form-select-sm" :class="{ 'is-invalid': senderRelationshipToRecipientRequired }" id="senderRelationshipToRecipient" @change="onChangeRelationshipToRecipient">
                                <option value="">- Select -</option>
                                <option>Self | Próprio</option>
                                <option>Spouse | Cônjuge</option>
                                <option>Parent | Pai/Mãe</option>
                                <option>Child | Filho(a)</option>
                                <option>Sibling | Irmão/Irmã</option>
                                <option>Grandparent | Avô/Avó</option>
                                <option>Grandchild | Neto/Neta</option>
                                <option>Friend | Amigo(a)</option>
                                <option>Fiancé/Fiancée | Noivo/Noiva</option>
                                <option>Other Relatives/Family | Outros Parentes/Família</option>
                                <option>Employee | Funcionário</option>
                                <option>Client | Cliente</option>
                                <option>Attorney | Advogado(a)</option>
                                <option>Guardian | Advogado(a)</option>
                                <option>Trustee | Procurador</option>
                                <option>Donation | Doação</option>
                                <option value="-1">Other | Outro (campo para inserir opção não listada)</option>
                            </select>
                            <div class="input-group input-group-sm" v-show="showRelationshipToRecipient">
                                <input type="text" v-model="information.senderRelationshipToRecipient" v-show="showRelationshipToRecipient" class="form-control form-control-sm" :class="{ 'is-invalid': senderRelationshipToRecipientRequired }" id="senderRelationshipToRecipient" name="senderRelationshipToRecipient" placeholder="" />
                                <button type="button" class="btn btn-light border" @click="resetRelationshipToRecipient">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div v-else>
                            <select v-model="information.senderRelationshipToRecipient" @change="onChangeSenderRelationshipToRecipient" class="form-select form-select-sm" :class="{ 'is-invalid': senderRelationshipToRecipientRequired }" id="senderRelationshipToRecipient" name="senderRelationshipToRecipientselect">
                                <option value="">- Select -</option>
                                <option v-for="item in relationshipOptions" :value="item.id + ' - ' + item.value" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <label for="purposeOfTransaction" class="form-label m-0 tiny-font">Purpose of transaction</label>
                        <span v-show="purposeOfTransactionRequired" class="mandatory-mark tiny-mandatory-mark">*</span>
                        <div v-if="!trangloOperation">
                            <select v-model="information.purposeOfTransaction" v-show="!showPurposeOfTransaction" class="form-select form-select-sm" :class="{ 'is-invalid': purposeOfTransactionRequired }" id="purposeOfTransaction" @change="onChangePurposeOfTransaction">
                                <option value="">- Select -</option>
                                <option>Bill payment | Pagamento de contas</option>
                                <option>Medical expenses | Despesas médicas</option>
                                <option>Educational expenses | Despesas educacionais</option>
                                <option>Other Service/Product payment | Pagamento de outros serviços/produtos</option>
                                <option>Family Support | Suporte familiar</option>
                                <option>Transfer of Assets | Transferência de ativos</option>
                                <option>Investments/Savings | Investimentos/Poupança</option>
                                <option>Child Support | Pensão alimenticia</option>
                                <option>Donation | Doação</option>
                                <option value="-1">Other | Outro (campo para inserir opção não listada)</option>
                            </select>
                            <div class="input-group input-group-sm" v-show="showPurposeOfTransaction">
                                <input type="text" v-show="showPurposeOfTransaction" v-model="information.purposeOfTransaction" class="form-control form-control-sm" :class="{ 'is-invalid': purposeOfTransactionRequired }" id="purposeOfTransaction" name="purposeOfTransaction" placeholder="" />
                                <button type="button" class="btn btn-light border" @click="resetPurposeOfTransaction">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div v-else>
                            <select v-model="information.purposeOfTransaction" class="form-select form-select-sm" :class="{ 'is-invalid': purposeOfTransactionRequired }" id="purposeOfTransaction" name="purposeOfTransactionselect">
                                <option value="">- Select -</option>
                                <option v-for="item in purposeOptions" :value="item.id + ' - ' + item.value" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <label for="messageToRecipient" class="form-label m-0 tiny-font">Message to recipient</label>
                    <textarea rows="3" v-model="information.messageToRecipient" class="form-control form-control-sm" id="messageToRecipient" placeholder="" />
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'NewOrderAdditionalInformationForm',
    data() {
        return {
            payMethods: [],
            loading: false,
            validating: false,
            trangloOperation: false,
            fundOfSourceOptions: [],
            purposeOptions: [],
            relationshipOptions: [],
            showOccupation: false,
            showPurposeOfTransaction: false,
            showRelationshipToRecipient: false,
            showSourceOfFunds: false,
            otherInformationDocuments: null,
            docFileUpload: '',
        };
    },
    mounted() {
        this.loadPayMethods();
        this.information.senderOccupation = this.order.sender.occupation;
    },
    methods: {
        load() {
            axios
                .get('/niloagent/createordersimple/additionalInformation', {
                    params: {
                        senderId: this.order.sender.clientId,
                        recipientId: this.order.recipient.clientId,
                        paymethodId: this.order.paymentInformation.paymentMethodId,
                    },
                })
                .then((response) => {
                    this.fundOfSourceOptions = response.data.fundOfSourceOptions;
                    this.purposeOptions = response.data.purposeOptions;
                    this.relationshipOptions = response.data.relationshipOptions;
                    this.trangloOperation = response.data.trangloOperation;
                })
                .catch((error) => {
                    this.currencies = null;
                    this.$toast.error(error.response.data.Message);
                });
        },
        loadPayMethods() {
            this.loading = true;
            axios
                .get('/niloagent/createorder/filter/additional/paymethodfromsender')
                .then((response) => {
                    this.payMethods = response.data;
                })
                .catch((error) => {
                    this.payMethods = null;
                    this.$toast.error(`${error.response.data.Message}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onChangeSenderPayMethod() {
            if (this.information.payMethod === 'DEBIT_CARD') {
                alert('For Debit Card Transactions a 0.20% fee of the total amount will be charged');
                this.$store.state.simpleOrder.paymentInformation.debitCardSurcharge = this.$store.state.simpleOrder.paymentInformation.amountSent * 0.002;
            } else {
                this.$store.state.simpleOrder.paymentInformation.debitCardSurcharge = 0;
            }
            this.$store.state.simpleOrder.additionalInformation.payMethod = this.information.payMethod;
        },
        onChangeSenderOccupation() {
            if (this.information.senderOccupation === '-1') {
                this.information.senderOccupation = '';
                this.showOccupation = true;
            } else {
                this.showOccupation = false;
            }
        },
        onChangeRelationshipToRecipient() {
            if (this.information.senderRelationshipToRecipient === '-1') {
                this.information.senderRelationshipToRecipient = '';
                this.showRelationshipToRecipient = true;
            } else {
                this.showRelationshipToRecipient = false;
            }
        },
        onChangePurposeOfTransaction() {
            if (this.information.purposeOfTransaction === '-1') {
                this.information.purposeOfTransaction = '';
                this.showPurposeOfTransaction = true;
            } else {
                this.showPurposeOfTransaction = false;
            }
        },
        onChangeSourceOfFunds() {
            if (this.information.sourceOfFunds === '-1') {
                this.information.sourceOfFunds = '';
                this.showSourceOfFunds = true;
            } else {
                this.showSourceOfFunds = false;
            }
        },
        resetSenderOccupation() {
            this.information.senderOccupation = '';
            this.showOccupation = false;
        },
        resetRelationshipToRecipient() {
            this.information.senderRelationshipToRecipient = '';
            this.showRelationshipToRecipient = false;
        },
        resetPurposeOfTransaction() {
            this.information.purposeOfTransaction = '';
            this.showPurposeOfTransaction = false;
        },
        resetSourceOfFunds() {
            this.information.sourceOfFunds = '';
            this.showSourceOfFunds = false;
        },
    },
    computed: {
        uploadUrl() {
            return `/niloagent/order/${this.orderId}/detail/savefile`;
        },
        order() {
            return this.$store.state.simpleOrder;
        },
        information() {
            return this.$store.state.simpleOrder.additionalInformation;
        },
        paymentMethodId() {
            return this.$store.state.simpleOrder.paymentInformation.paymentMethodId;
        },
        senderRelationRecipient() {
            // Obtém os valores das opções diretamente do elemento select
            this.$nextTick(() => {
                const options = Array.from(this.$refs.senderRelationshipToRecipientSelect.options).map((option) => option.value);

                if (this.$store.state.simpleOrder.recipient.relationshipToSender != undefined) {
                    // Verifica se o valor selecionado está dentro das opções
                    var relationshipToSenderChecked = options.includes(this.$store.state.simpleOrder.recipient.relationshipToSender);
                    if (!relationshipToSenderChecked) this.showRelationshipToRecipient = true;
                }
            });

            return this.$store.state.simpleOrder.recipient.relationshipToSender;
        },
        senderOccupation() {
            // Obtém os valores das opções diretamente do elemento select
            this.$nextTick(() => {
                const options = Array.from(this.$refs.senderOccupationSelect.options).map((option) => option.value);

                // Verifica se o valor selecionado está dentro das opções
                var occupationOptionChecked = options.includes(this.$store.state.simpleOrder.sender.occupation);
                if (!occupationOptionChecked) this.showOccupation = true;
            });

            return this.$store.state.simpleOrder.sender.occupation;
        },
        senderOccupationRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'senderOccupation');
        },
        sourceOfFundsRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'sourceOfFunds');
        },
        payMethodRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'payMethod');
        },
        senderRelationshipToRecipientRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'senderRelationshipToRecipient');
        },
        purposeOfTransactionRequired() {
            return this.$store.state.simpleOrder.modelErrors != null && this.$store.state.simpleOrder.modelErrors.some((error) => error.Field === 'purposeOfTransaction');
        },
    },
    watch: {
        paymentMethodId(value) {
            if (value > 0) {
                this.load();
            }
        },
        senderRelationRecipient(value) {
            this.information.senderRelationshipToRecipient = value;
        },
        senderOccupation(value) {
            this.information.senderOccupation = value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/simpleOrder.scss';
</style>
